<template>
    <div class="identity-provider-login">
        <div>
            <google-login-button :sso-login-url="ssoLoginUrl" />
            <div v-if="shouldShowUnexpectedError" class="identity-provider-login__error">
                {{ unexpectedError }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import langConsts from '@/lang/en';
import loginConsts from '@/consts/loginConsts';
import router from '@/router';

export default {
    name: 'IdentityProviderLogin',
    components: {
        GoogleLoginButton: () => import('./GoogleLogin/GoogleLoginButton')
    },
    props: {
        didLoginAttemptFail: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState('login', ['ssoLoginUrl', 'loginProcessInitialized', 'isUserLoginFlowSso', 'isAppLoginFlowSsoOnly']),

        loginTitle() {
            return langConsts.titleLogin;
        },
        unexpectedError() {
            return langConsts.unexpectedError;
        },
        shouldShowUnexpectedError() {
            return this.ssoLoginUrl === undefined || this.didLoginAttemptFail;
        }
    },
    async created() {
        if (this.loginProcessInitialized === false || (this.isUserLoginFlowSso === false && this.isAppLoginFlowSsoOnly === false)) {
            router.replace(`${loginConsts.urls.baseLoginAppUrl}${loginConsts.urls.urlPathFailedLoginAttempt}`);
        }
    }
};
</script>

<style lang="scss">
.identity-provider-login {
    &__error {
        color: $outrageous-orange;
        margin-top: 1rem;
    }
}
</style>
